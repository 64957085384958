
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import { AuthState } from '@/ts/states/auth/authState'
  import { Claims } from '@/ts/constants/claims'
  import { Crud } from '@/ts/enums/crud'
  import { SearchMode } from '@/ts/enums/searchMode'
  import { SearchState } from '@/ts/states/search/searchState'
  import { Person } from '@/ts/models/person'
  import { ViewStatus } from '@/ts/enums/viewStatus'

  import pluralize from 'pluralize'

  import AsyncCarousel from '@/components/carousels/AsyncCarousel.vue'
  import PersonSlide from '@/components/carousels/slides/PersonSlide.vue'

  @Component({
    components: {
      AsyncCarousel,
      PersonSlide
    }
  })
  export default class PersonsTab extends Vue {

    /** PROPERTIES */

    @Prop({ default: false })
    public selectedOnly!: boolean

    @Prop()
    public state!: SearchState

    @Prop({ default: true })
    public visible!: boolean


    /** PRIVATE PROPERIES  */

    private authState = new AuthState(this.$store)


    /** LIFECYCLE */

    public mounted() {
      if (this.state.hasState) {
        this.$nextTick(() => {
          this.scrollToSlide(this.slideIndex)
        })
      }
    }

    public beforeUpdate() {
      window.removeEventListener('scroll', this.onScroll, true)
    }

    public updated() {
      window.addEventListener('scroll', this.onScroll, true);
    }

    public unmounted() {
      window.removeEventListener('scroll', this.onScroll, true)
    }


    /** COMPUTED PROPERTIES */

    protected get canViewProfile() {
      return this.authState.user.hasPermission(Claims.PROFILE, Crud.READ)
  }

    protected get isUpdating() {
      return this.status == ViewStatus.UPDATING
    }

    protected get isVisible() {
      return this.visible && this.status > ViewStatus.NONE && this.status < ViewStatus.FAILED
    }

    protected get persons() {
      return this.personsState.people
    }

    protected get personsState() {
      return (this.selectedOnly) ? this.state.persons.selected : this.state.persons
    }

    protected get selectedPersonIds() {
      return this.state.persons.selected.ids
    }

    protected get slideIndex() {
      return this.personsState.slideIndex
    }

    protected get slidesToShow() {
      return this.state.slidesToShow
    }

    protected get status() {
      return this.personsState.status
    }

    protected get title() {
      return (this.isUpdating) ? `Updating profiles...` : `Showing ${pluralize('customer profile', this.personsState.count, true)}`
    }

    protected get totalSlides() {
      return this.persons.length
    }


    /** EVENTS */

    protected onAfterChange(index: number) {
      this.state.persons.slideIndex = index
      if (index >= this.state.persons.length - this.state.slidesToShow * 2) {
        this.loadPersons()
      }
    }

    protected onChange(checked: boolean, person: Person) {
      if (!checked) {
        this.state.persons.selected.deselect(person)
      }
      else {
        this.state.persons.selected.select(person)
      }
    }

    protected onClick(person: Person) {
      this.state.mode = SearchMode.PERSONS;
      this.state.selectedId = person.id
      this.$emit('click', person)
    }

    protected onUpdate(person: Person) {
      this.state.persons.update(person)
    }


    /** PRIVATE METHODS */

    protected isSelected(person: Person) {
      return this.state.persons.selected.isSelected(person)
    }

    protected onScroll() {
      const trs = document.querySelectorAll('tr')
      for (const tr of trs) {
        if (tr.dataset.pk != null && this.isInViewport(tr)) {
          const index = this.persons.findIndex(p => p.id == Number(tr.dataset.pk))
          if (index > -1) {
            this.scrollToSlide(index)
            break;
          }
        }
      }
    }

    private isInViewport(el: HTMLElement) {
      const rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      )
    }

    private loadPersons() {
      if (this.personsState.hasMoreResults) {
        const params = this.personsState.queryParams
        ++params.currentPage

        this.personsState.fetch(params)
      }
    }

    private scrollToSlide(index: number, animated = true) {
      const carousel = this.$refs.carousel as any
      if (carousel != undefined) {
        carousel.goToSlide(index, animated)
      }
    }
  }

