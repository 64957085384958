
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
  import { AuthState } from '@/ts/states/auth/authState'
  import { Claims } from '@/ts/constants/claims'
  import { Crud } from '@/ts/enums/crud'
  import { Person } from '@/ts/models/person'
  import { PersonStatus } from '@/ts/models/personStatus'
  import { PersonStatusType } from '@/ts/enums/personStatusType'
  import { IScrollContext } from '@/ts/interfaces/scrollContext'
  import { SearchMode } from '@/ts/enums/searchMode'
  import { SearchState } from '@/ts/states/search/searchState'
  import { ISortContext } from '@/ts/interfaces/sortContext'
  import { ViewStatus } from '@/ts/enums/viewStatus'

  import StatusKey from '@/components/legends/StatusKey.vue'
  import StatusLegend from '@/components/legends/StatusLegend.vue'


  @Component({
    components: {
      StatusKey,
      StatusLegend
    }
  })

  export default class SearchResults extends Vue {

    /** PROPERTIES */

    @Prop()
    public state!: SearchState


    /** PRIVATE PROPERTIES */

    protected selectedIds = this.state.persons.selected.ids

    private authState = new AuthState(this.$store)
    private scrollContext?: IScrollContext


    /** OBSERVERS */

    @Watch('persons', { immediate: false, deep: false })
    protected onPersonsChanged() {
      this.scrollContext?.loaded()
    }

    @Watch('state.persons.selected.ids')
    protected onStateSelectedIdsChanged(ids: number[]) {
      this.selectedIds = ids
    }

    @Watch('selectedIds', { immediate: false, deep: false })
    protected onSelectedIdsChanged(newIds: number[], oldIds: number[]) {
      if (oldIds == undefined) {
        const person = this.person(newIds[0])!
        this.state.persons.selected.select(person)
      }
      else if (newIds.length > oldIds.length) {
        const id = newIds.filter(i => !oldIds.includes(i))[0]
        const person = this.person(id)!
        this.state.persons.selected.select(person)
      }
      else if (newIds.length < oldIds.length) {
        const id = oldIds.filter(i => !newIds.includes(i))[0]
        const person = this.person(id)!
        this.state.persons.selected.deselect(person)
      }
    }


    /** COMPUTED PROPERTIES */

    protected get canViewProfile() {
      return this.authState.user.hasPermission(Claims.PROFILE, Crud.READ)
    }

    protected get hasMoreResults() {
      return this.state.persons.hasMoreResults
    }

    protected get isLoading() {
      return this.status == ViewStatus.IN_PROGRESS
    }

    protected get isSorting() {
      return this.status == ViewStatus.SORTING
    }

    protected get isSuccessful() {
      return this.state.persons.status == ViewStatus.SUCCEEDED
    }

    protected get isVisible() {
      return this.status > ViewStatus.NONE
    }

    protected get queryParams() {
      return this.state.persons.queryParams
    }

    protected get personCount() {
      return (this.state.persons.totalCount > 0) ? this.state.persons.totalCount : 'all'
    }

    protected get persons() {
      return this.state.persons.people
    }

    protected get status() {
      return this.state.persons.status
    }


    /** EVENTS */

    protected async onExcelClick() {
      //const repo = new PersonRepository();
      //await repo.GetAllPersons()
      //const baseUrl = 'https://localhost:44390/api';
      //window.location.href = baseUrl + '/person/' + 'ExportToExcel';

    }

    protected onInfinite(context: IScrollContext) {
      this.scrollContext = context

      ++this.queryParams.currentPage
      this.state.persons.fetch(this.queryParams)
    }

    protected onRowClicked(person: Person) {
      this.state.mode = SearchMode.PERSONS
      this.state.selectedId = person.id
      this.$emit('click', person)
    }

    protected onSortChanged(context: ISortContext) {    
      this.state.persons.sort(context)
    }



    /** PRIVATE METHODS */

    protected isRowSelected(person: Person) {
      return this.state.persons.selected.isSelected(person) ? 'bg-selected cursor-pointer' : 'cursor-pointer'
    }

    protected person(id: number) {
      return this.persons.find(p => p.id == id)
    }

    protected personStatusVariant(status: PersonStatusType) {
      return PersonStatus.variant(status)
    }
  }

