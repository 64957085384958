
  import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
  import { AuthState } from '@/ts/states/auth/authState'
  import { Claims } from '@/ts/constants/claims'
  import { Crud } from '@/ts/enums/crud'
  import { EventLog } from '@/ts/models/eventLog'
  import { Patron } from '@/ts/models/patron'
  import { Person } from '@/ts/models/person'
  import { SearchMode } from '@/ts/enums/searchMode'
  import { SearchState } from '@/ts/states/search/searchState'
  import { ViewStatus } from '@/ts/enums/viewStatus'

  import pluralize from 'pluralize'

  import AsyncCarousel from '@/components/carousels/AsyncCarousel.vue'
  import EventSlide from '@/components/carousels/slides/EventSlide.vue'

  @Component({
    components: {
      AsyncCarousel,
      EventSlide
    }
  })
  export default class EventsTab extends Vue {

    /** PROPERTIES */

    @Prop({ default: false })
    public showToggle!: boolean

    @Prop()
    public state!: SearchState

    @Prop({ default: true })
    public visible!: boolean


    /** PRIVATE PROPERIES  */

    private authState = new AuthState(this.$store)


    /** LIFECYCLE */

    public mounted() {
      if (this.state.hasState) {
        this.$nextTick(() => {
          this.scrollToSlide(this.slideIndex)
        })
      }
    }

    /** OBSERVERS */
    @Watch('showAll')
    protected onShowAllEventsChanged(showAll: boolean) {
      this.state.eventLogs.slideIndex = 0
      const params = this.state.eventLogs.queryParams
      params.selectedIds = (showAll) ? [] : this.state.persons.selected.ids

      this.state.eventLogs.fetch(params)
    }

    /** COMPUTED PROPERTIES */

    protected get canViewProfile() {
      return this.authState.user.hasPermission(Claims.PROFILE, Crud.READ)
   }

    protected get events() {
      return this.state.eventLogs.events
    }

    protected get hasSelection() {
      return this.selectedPersonIds.length > 0
    }

    protected get isLoading() {
      return this.status == ViewStatus.IN_PROGRESS
    }

    protected get isUpdating() {
      return this.status == ViewStatus.UPDATING
    }

    protected get isVisible() {
      return this.visible && this.status > ViewStatus.NONE && this.status < ViewStatus.FAILED
    }

    protected get selectedPersonIds() {
      return this.state.persons.selected.ids
    }

    protected get showAll() {
      return this.state.eventLogs.showAll
    }

    protected get slideIndex() {
      return this.state.eventLogs.slideIndex
    }

    protected get status() {
      return this.state.eventLogs.status
    }

    protected get title() {
      return (this.isUpdating || this.isLoading) ? `Updating events...` : `Showing ${pluralize('event', this.state.eventLogs.count, true)} for ${pluralize('customer profile', (this.state.eventLogs.showAll) ? this.state.persons.count : this.state.persons.selected.length, true)}`
    }

    protected get totalSlides() {
      return this.events.length
    }

   
    /** EVENTS */

    protected onAfterChange(index: number) {
      this.state.eventLogs.slideIndex = index
      if (index >= this.state.eventLogs.length - this.state.slidesToShow * 2) {
        this.loadEvents()
      }
    }

    protected onChange(checked: boolean, event: EventLog) {
      const person = this.convert(event.patron!)
      if (!checked) {
        this.state.persons.selected.deselect(person)
      }
      else {
        this.state.persons.selected.select(person)
      }

      this.showToast(checked, person)
    }

    protected onClick(event: EventLog) {
      this.state.mode = SearchMode.EVENTS
      this.state.selectedId = event.id
      const person = this.convert(event.patron!)
      this.$emit('click', person)
    }


/** PRIVATE METHODS */

    protected isSelected(personId: number) {
      return this.selectedPersonIds.includes(personId)
    }

    private convert(patron: Patron) {
      const person = patron.person!.copy()
      patron.person = undefined
      person.patrons.push(patron)

      return person
    }

    private loadEvents() {
      if (this.state.eventLogs.hasMoreResults) {
        const queryParams = this.state.eventLogs.queryParams
        ++queryParams.currentPage

        this.state.eventLogs.fetch(queryParams)
      }
    }

    private scrollToSlide(index: number, animated = true) {
      const carousel = this.$refs.carousel as any
      if (carousel != undefined) {
        carousel.goToSlide(index, animated)
      }
    }

    private showToast(checked: boolean, person: Person) {
      this.$bvToast.toast(`${person.fullname} was ${checked ? 'added to' : 'removed from'} your watchlist`, {
        title: 'NightKey notification',
        autoHideDelay: 3000,
        appendToast: true
      })
    }
  }

