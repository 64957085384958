
  import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
  import { SearchState } from '@/ts/states/search/searchState'
  import { Person } from '@/ts/models/person'
  
  import EventsTab from '@/components/carousels/tabs/EventsTab.vue'
  import PersonsTab from '@/components/carousels/tabs/PersonsTab.vue'

  @Component({
    components: {
      EventsTab,
      PersonsTab
    }
  })
  export default class SearchFooter extends Vue {

    /** PROPERTIES */

    @Prop({ default: false })
    public showPanel!: boolean

    @Prop({ default: false })
    public showToggle!: boolean

    @Prop()
    public state!: SearchState


    /** PRIVATE PROPERTIES */

    protected slideDown = false
    protected slideIn = false
    protected slideOut = false


    /** LIFECYCLE */

    public mounted() {
      this.state.slidesToShow = Math.floor((window.innerWidth - 72) / 140)
    }

    public beforeUpdate() {
      this.state.slidesToShow = Math.floor((window.innerWidth - 72) / 140)
    }


    /** OBSERVERS */

    @Watch('selectedPersonIds')
    protected onSelectedPersonIdsChanged(ids: number[]) {
      if (!this.state.eventLogs.showAll) {
        const params = this.state.eventLogs.queryParams
        params.selectedIds = (ids.length <= 0) ? [] : this.selectedPersonIds

        this.state.eventLogs.slideIndex = 0
        this.state.eventLogs.fetch(params)
      }
    }

    @Watch('showPanel', { immediate: true })
    protected onShowPanelChanged(show: boolean) {
      if (show && this.slideIn == true) {
        this.slideOut = !show
      }

      this.slideIn = show
    }


    /** COMPUTED PROPERTIES */

    protected get chevron() {
      return (this.slideDown) ? 'chevron-up' : 'chevron-down'
    }

    protected get hasEvents() {
      return this.state.eventLogs.hasState
    }

    protected get hasPersons() {
      return this.state.persons.hasState
    }

    protected get hasResults() {
      return this.hasEvents || this.hasPersons
    }

    protected get selectedPersonIds() {
      return this.state.persons.selected.ids
    }

    protected get showEvents() {
      return !this.slideDown && this.hasEvents
    }

    protected get showPersons() {
      return !this.slideDown && this.hasPersons
    }

    protected get tabIndex() {
      return this.state.tabIndex
    }


    /** EVENTS */

    protected onClick(person: Person) {
      this.$emit('click', person)
    }

    protected onClose() {
      this.slideDown = !this.slideDown
    }
  }

